import React from 'react'
import FormField from '../common/FormField'
import Button from '../common/Button/Button'

const PostNotification = (props) => {
  const {
    postID,
    selectedNotification,
    postData,
    handleInputChange,
    getPostData,
    postButtonValidation,
    loadingAPI,
  } = props

  return (
    <>
      {selectedNotification?.id === 7 && (
        <div className="row mt-4">
          <div className="col-5">
            <FormField
              className="col-8 col-lg-7 m-0"
              value={postID ?? ''}
              name="Post"
              type="text"
              label="Post"
              para="Enter the Post's ID found in post database"
              placeholder="Post ID#"
              paraVisibility={true}
              onChange={(e) => handleInputChange(e, 'postId')}
              characterLeftVisible={false}
            />
            <Button
              type="button"
              className="send-button mt-4"
              name="Check Post"
              onClick={() => getPostData(postID)}
              disabled={
                postButtonValidation || loadingAPI === true ? true : false
              }
            />
          </div>
          <div className="col-7 mt-4">
            <div>
              <b>Title: </b>
              {postData?.postTitle ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={postData?.postTitle}
            >
              <b>Text: </b>
              {postData?.postText ?? ''}
            </div>
            <div
              className="thirty-chars twitter-div"
              title={postData?.directUrl}
            >
              <div>
                <b>Link</b>:
                {postData?.directUrl ? (
                  <a
                    href={postData?.directUrl ?? 'N/A'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link URL
                  </a>
                ) : null}
              </div>
            </div>
            <div
              className="thirty-chars twitter-div"
              title={postData?.mediaUrl}
            >
              <div>
                <b>Media</b>:
                {postData?.mediaUrl ? (
                  <a
                    href={postData?.mediaUrl ?? 'N/A'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link URL
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default PostNotification
